import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from '../components/PublicNavbar';
import Hero from '../components/Hero';
import ChristmasServicesBento from '../components/ChristmasServicesBento';
import PublicFooter from '../components/PublicFooter';
import AboutUs from '../components/AboutUs';
import CTA from '../components/Cta';
import WhatsAppFloatingButton from '../components/WhatsAppFloatingButton';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const section = searchParams.get('section');
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }, [location]);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <main>
        <Hero />
        
        <motion.div 
          id="servicii" 
          className="mx-auto max-w-2xl lg:max-w-7xl"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <ChristmasServicesBento />
        </motion.div>
        
        <motion.div 
          id="despre-noi" 
          className="mx-auto max-w-2xl lg:max-w-7xl"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <AboutUs />
        </motion.div>
        
        {/* <div className="mx-auto max-w-2xl lg:max-w-7xl">
          <CTA />
        </div> */}
        <WhatsAppFloatingButton />
      </main>
      
      <PublicFooter />
    </div>
  );
};

export default Home;
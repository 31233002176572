import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const checkAuthStatus = useCallback(() => {
    const token = localStorage.getItem('accessToken');
    console.log('useAuth: Verificare token:', !!token);
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    checkAuthStatus();

    const handleStorageChange = () => {
      checkAuthStatus();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [checkAuthStatus]);

  const login = async (email: string, password: string) => {
    console.log('useAuth: Încercare de login');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { email, password });

      console.log('useAuth: Răspuns primit de la server');

      const { accessToken, refreshToken } = response.data;
      console.log('useAuth: Token-uri primite de la server');
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      checkAuthStatus();

      console.log('useAuth: isAuthenticated actualizat');
      console.log('useAuth: Încercare de navigare către /dashboard');
      navigate('/dashboard');
    } catch (error) {
      console.error('useAuth: Eroare la autentificare:', error);
      throw error;
    }
  };

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/refresh`, { refreshToken });
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
      
      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      
      return newAccessToken;
    } catch (error) {
      console.error('Eroare la reînnoirea token-ului:', error);
      logout();
      throw error;
    }
  };

  const logout = () => {
    console.log('useAuth: Logout inițiat');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    checkAuthStatus();
    navigate('/login');
  };

  return {
    isAuthenticated,
    login,
    logout,
    checkAuthStatus,
    refreshAccessToken
  };
};
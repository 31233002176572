import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || 'https://localhost:5000';

export interface Character {
  _id: string;
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  phone2?: string;
  cnp: string;
  address: string;
  iban: string;
  contractNumber: string;
  eyeColor: string;
  experience: number;
  birthDate: Date | string;
  languages: string[];
  profileStatus: 'active' | 'inactive';
  photos: string[];
  characterType: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export const generateSlug = (firstName: string, lastName: string): string => {
  return `${firstName.toLowerCase()}-${lastName.toLowerCase()}`.replace(/[^a-z0-9-]/g, '-');
};

export const getCharacters = async (): Promise<Character[]> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get<Character[]>(`${API_URL}/api/characters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea personajelor', error);
    throw error;
  }
};

export const addCharacter = async (characterData: FormData): Promise<Character> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post<Character>(`${API_URL}/api/characters`, characterData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la adăugarea personajului', error);
    throw error;
  }
};

export const getAvatarUrl = (firstName: string, lastName: string): string => {
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  return `${API_URL}/api/characters/avatar/${encodeURIComponent(initials)}`;
};

export const getCharacterById = async (id: string): Promise<Character> => {
  try {
    if (!/^[0-9a-fA-F]{24}$/.test(id)) {
      throw new Error('ID-ul personajului nu este valid');
    }

    const token = localStorage.getItem('accessToken');
    const response = await axios.get<Character>(`${API_URL}/api/characters/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    // Asigurăm-ne că avem URL-uri complete pentru fotografii, fără a dubla API_URL
    response.data.photos = response.data.photos.map(photo => 
      photo.startsWith('http') ? photo : `${API_URL}${photo.startsWith('/') ? '' : '/'}${photo}`
    );
    
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea detaliilor personajului', error);
    throw error;
  }
};

export const addCharacterPhotos = async (modelId: string, formData: FormData): Promise<Character> => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.post<Character>(`${API_URL}/api/characters/${modelId}/photos`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const deleteCharacterPhoto = async (modelId: string, photoIndex: number): Promise<string[]> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.delete<{ message: string; updatedPhotos: string[] }>(
      `${API_URL}/api/characters/${modelId}/photos`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { photoIndex },
      }
    );
    
    // Returnăm lista actualizată de fotografii
    return response.data.updatedPhotos.map(photo => 
      photo.startsWith('http') ? photo : `${API_URL}/${photo}`
    );
  } catch (error) {
    console.error('Eroare la ștergerea fotografiei', error);
    throw error;
  }
};

export const updateCharacter = async (id: string, updateData: Partial<Character>): Promise<Character> => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put<Character>(`${API_URL}/api/characters/${id}`, updateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la actualizarea personajului', error);
    throw error;
  }
};

export const deleteCharacter = async (id: string): Promise<void> => {
  try {
    const token = localStorage.getItem('accessToken');
    await axios.delete(`${API_URL}/api/characters/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Eroare la ștergerea personajului', error);
    throw error;
  }
};

import React from 'react';

const Soon: React.FC = () => {
  return (
    <div className="bg-gray-50 min-h-screen flex items-center justify-center p-4">
      <div className="bg-white shadow-xl rounded-lg p-8 max-w-2xl w-full text-center">
        <img 
          src="/static/media/inchiriazamoscraciun.8aba8521f5cd9ae0a17e.png" 
          alt="Închiriază Moș Crăciun Logo" 
          className="w-32 h-auto mb-8 mx-auto"
        />
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          Site în Mentenanță
        </h1>
        <p className="text-xl text-gray-600 mb-6">
          Efectuăm câteva lucrări de întreținere pentru a îmbunătăți experiența dumneavoastră. Revenim imediat!
        </p>
      </div>
    </div>
  );
};

export default Soon;
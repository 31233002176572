import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Input from '../../shared/Input';
import Button from '../../shared/Button';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('LoginForm: handleSubmit început');
    setError('');

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailPattern.test(email)) {
      console.log('LoginForm: Email invalid');
      setError('Email invalid');
      return;
    }

    if (!password) {
      console.log('LoginForm: Parola lipsă');
      setError('Parola este obligatorie');
      return;
    }

    try {
      console.log('LoginForm: Încercare de autentificare');
      await login(email, password);
      console.log('LoginForm: Autentificare reușită');
    } catch (error) {
      console.error('LoginForm: Eroare la autentificare', error);
      setError('Eroare la autentificare');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <Input
        label="Email"
        id="email"
        name="email"
        type="email"
        placeholder="you@example.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={error && !email ? 'Email obligatoriu' : ''}
        autoComplete="email"
      />

      <Input
        label="Password"
        id="password"
        name="password"
        type="password"
        placeholder="Introduceți parola"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={error && !password ? 'Parola obligatorie' : ''}
        autoComplete="current-password"
      />
      {error && <p className="text-red-500 text-sm">{error}</p>}

      <Button text="Sign in" type="submit" className="w-full" />
    </form>
  );
};

export default LoginForm;
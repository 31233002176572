import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, PencilIcon, UserIcon, PhoneIcon, EnvelopeIcon, MapPinIcon, CalendarIcon, ClockIcon, CurrencyDollarIcon, TruckIcon, ChatBubbleLeftEllipsisIcon, SparklesIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Order, cancelOrder } from '../../services/orderService';
import { formatInTimeZone } from 'date-fns-tz';
import { ro } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

interface OrderDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: Order | null;
  onOrderUpdate?: (updatedOrder: Order) => void;
}

const OrderDrawer: React.FC<OrderDrawerProps> = ({ open, setOpen, order, onOrderUpdate }) => {
  const navigate = useNavigate();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  if (!order) return null;

  const formatDate = (dateString: string, timeZone: string = 'Europe/Bucharest') => {
    return formatInTimeZone(new Date(dateString), timeZone, 'EEEE, d MMM yyyy', { locale: ro });
  };

  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };  

  const calculateExtraModelsFee = () => {
    return order.extraCharacters?.reduce((total, extra) => total + extra.modelFee, 0) || 0;
  };

  const renderField = (Icon: React.ElementType, label: string, value: string | undefined | null, type: 'text' | 'phone' | 'email' | 'address' = 'text') => {
    const displayValue = value || <span className="text-red-500">Nespecificat</span>;
    return (
      <div className="flex flex-col space-y-1">
        <div className="flex items-center space-x-2">
          <Icon className="h-5 w-5 text-gray-400" />
          <span className="text-sm font-medium text-gray-500">{label}:</span>
        </div>
        {type === 'phone' && value ? (
          <a href={`tel:${value}`} className="text-sm font-medium text-indigo-600 ml-7">{value}</a>
        ) : type === 'email' && value ? (
          <a href={`mailto:${value}`} className="text-sm font-medium text-indigo-600 ml-7">{value}</a>
        ) : type === 'address' ? (
          <span className="text-sm font-medium ml-7">{displayValue}</span>
        ) : (
          <span className="text-sm font-medium ml-7">{displayValue}</span>
        )}
      </div>
    );
  };

  const handleEdit = () => {
    const editUrl = `/dashboard/comenzi/${order.character.firstName.toLowerCase()}-${order.character.lastName.toLowerCase()}-${order._id}?edit=true`;
    setOpen(false);
    navigate(editUrl);
  };

  const handleCancelOrder = async () => {
    if (!order) return;
    
    try {
      setIsProcessing(true);
      const updatedOrder = await cancelOrder(order._id, cancellationReason);
      
      console.log('Comanda actualizată:', updatedOrder); // Verifică statusul după actualizare
      
      if (onOrderUpdate && updatedOrder) {
        onOrderUpdate(updatedOrder);
      }
      
      setShowCancelDialog(false);
      setOpen(false);
    } catch (error) {
      console.error('Eroare la anularea comenzii:', error);
    } finally {
      setIsProcessing(false);
    }
  };  

  const handleWhatsAppMessage = () => {
    const phoneNumber = order.client?.phone?.replace(/\s/g, '') || '';
    const message = encodeURIComponent(
      `Bună ziua,\n\n` +
      `Vă reamintesc rezervarea dumneavoastră de Moș Crăciun cu următoarele detalii:\n\n` +
      `📅 Data și Ora: ${formatDate(order.period.startDate)}\n` +
      `⏰ Interval orar: ${formatTime(order.period.startTime)} - ${formatTime(order.period.endTime)}\n` +
      `📍 Adresă: "${order.address}"\n` +
      `🏘️ Zonă: "${order.zone}"\n\n` +
      `Pentru reconfirmarea rezervării, vă rog să-mi trimiteți un mesaj cu adresa dumneavoastră de email.\n\n` +
      `Vă mulțumesc!`
    );
  
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };  

  return (
    <>
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 desktop-margin flex max-w-full pl-10">
                <Transition.Child
                  as={React.Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full desktop-border flex-col overflow-y-auto bg-white shadow-xl">
                      <div className="sticky top-0 z-10 bg-white px-4 sm:px-6 py-6 border-b border-gray-200">
                        <div className="flex items-start justify-between">
                          <div>
                            <Dialog.Title className="text-xl font-bold leading-6 text-gray-900">
                              Detalii Comandă
                            </Dialog.Title>
                            <p className="mt-1 text-sm text-gray-500">
                              Previzualizare și acțiuni rapide pentru comandă.
                            </p>
                          </div>
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4 sm:px-6 pb-6">
                        <div className="mt-6 space-y-4">
                          <button
                            onClick={handleEdit}
                            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <PencilIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                            Editează comanda
                          </button>

                          {order.status !== 'cancelled' && (
                            <button
                              onClick={() => setShowCancelDialog(true)}
                              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                              <ExclamationTriangleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                              Anulează comanda
                            </button>
                          )}

                          <button
                            onClick={handleWhatsAppMessage}
                            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          >
                            <svg className="mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                              <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                            </svg>
                            Mesaj Client WhatsApp
                          </button>

                          <section>
                            <h3 className="text-lg font-semibold text-gray-900 mb-3">Informații Client</h3>
                            <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                              {renderField(UserIcon, "Nume", order.client?.name)}
                              {renderField(PhoneIcon, "Telefon", order.client?.phone, "phone")}
                              {renderField(EnvelopeIcon, "Email", order.client?.email, "email")}
                            </div>
                          </section>

                          <section>
                            <h3 className="text-lg font-semibold text-gray-900 mb-3">Mențiuni Client</h3>
                            <div className="bg-gray-50 rounded-lg p-4 shadow-sm border border-gray-300">
                              <p className="text-gray-700 italic">
                                {order.client?.mentiuni || <span className="text-red-500">Nespecificat</span>}
                              </p>
                            </div>
                          </section>

                          <section>
                            <h3 className="text-lg font-semibold text-gray-900 mb-3">Detalii Comandă</h3>
                            <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                              <div className="flex items-center space-x-2">
                                <CalendarIcon className="h-5 w-5 text-gray-400" />
                                <ClockIcon className="h-5 w-5 text-gray-400" />
                                <span className="text-sm font-medium text-gray-500">Data și Ora:</span>
                              </div>
                              <p className="text-sm font-medium text-green-600 ml-7">
                                {formatDate(order.period.startDate)}, {formatTime(order.period.startTime)} - {formatTime(order.period.endTime)}
                              </p>
                              {renderField(MapPinIcon, "Adresă", order.address, "address")}
                              {renderField(MapPinIcon, "Zonă", order.zone, "address")}
                              <div className="flex items-center space-x-2">
                                <span className="text-sm font-medium text-gray-500">Status:</span>
                                <span className={`text-sm font-medium ${
                                  order.status === 'active' ? 'text-green-600' :
                                  order.status === 'completed' ? 'text-blue-600' :
                                  'text-red-600'
                                }`}>
                                  {order.status}
                                </span>
                              </div>
                            </div>
                          </section>

                          <section>
                            <h3 className="text-lg font-semibold text-gray-900 mb-3">Informații Model</h3>
                            <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                              {renderField(UserIcon, "Nume Model", `${order.character.firstName} ${order.character.lastName}`)}
                              {renderField(SparklesIcon, "Tip Character", order.character.characterType)}
                            </div>
                          </section>

                          {order.extraCharacters && order.extraCharacters.length > 0 && (
                            <section>
                              <h3 className="text-lg font-semibold text-gray-900 mb-3">Modele Extra</h3>
                              <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                                {order.extraCharacters.map((extra, index) => (
                                  <p key={index}>
                                    <span className="text-indigo-600 font-semibold">{extra.character.firstName} {extra.character.lastName}</span>{' '}
                                    - {extra.character.characterType} (<span className="text-green-600 font-semibold">{extra.modelFee} RON</span>)
                                  </p>
                                ))}
                              </div>
                            </section>
                          )}

                          {order.driver && (
                            <section>
                              <h3 className="text-lg font-semibold text-gray-900 mb-3">Șofer</h3>
                              <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                                {renderField(UserIcon, "Nume Șofer", `${order.driver.firstName} ${order.driver.lastName}`)}
                                {renderField(PhoneIcon, "Telefon", order.driver.phone, "phone")}
                              </div>
                            </section>
                          )}

                          <section>
                            <h3 className="text-lg font-semibold text-gray-900 mb-3">Detalii Plată</h3>
                            <div className="bg-gray-50 rounded-lg p-4 space-y-2 shadow-sm border border-gray-300">
                              {renderField(CurrencyDollarIcon, "Total", `${order.billingInfo.totalAmount} RON`)}
                              {renderField(UserIcon, "Tarif Model Principal", `${order.billingInfo.modelFee} RON`)}
                              {renderField(UserIcon, "Tarif Modele Extra", `${calculateExtraModelsFee()} RON`)}
                              {renderField(TruckIcon, "Tarif Șofer", `${order.billingInfo.driverFee} RON`)}
                            </div>
                          </section>

                          {order.notes && (
                            <section>
                              <h3 className="text-lg font-semibold text-gray-900 mb-3">Note</h3>
                              <div className="bg-gray-50 rounded-lg p-4 shadow-sm border border-gray-300">
                                {renderField(ChatBubbleLeftEllipsisIcon, "Mențiuni", order.notes)}
                              </div>
                            </section>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Dialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6 shadow-xl">
            <div className="flex items-center justify-center mb-4">
              <ExclamationTriangleIcon className="h-12 w-12 text-red-600" aria-hidden="true" />
            </div>
            
            <Dialog.Title className="text-lg font-medium text-center mb-4">
              Confirmare Anulare Comandă
            </Dialog.Title>

            <div className="mt-4">
              <label htmlFor="cancellationReason" className="block text-sm font-medium text-gray-700 mb-2">
                Motiv Anulare
              </label>
              <textarea
                id="cancellationReason"
                rows={3}
                className="w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm focus:border-red-500 focus:ring-red-500"
                placeholder="Introduceți motivul anulării..."
                value={cancellationReason}
                onChange={(e) => setCancellationReason(e.target.value)}
              />
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => setShowCancelDialog(false)}
              >
                Renunță
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={handleCancelOrder}
                disabled={isProcessing}
              >
                {isProcessing ? 'Se procesează...' : 'Confirmă Anularea'}
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default OrderDrawer;
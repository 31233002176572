import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { useAuth } from '../../hooks/useAuth';

const Login = () => {
  const { isAuthenticated, checkAuthStatus } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('Login: Utilizator deja autentificat, redirecționare către dashboard');
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  if (isAuthenticated) {
    return <div>Se încarcă...</div>;
  }

  return (
    <HelmetProvider>
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50 px-6 py-12 lg:px-8">
        <Helmet>
          <title>Autentificare - Inchiriaza Mos Craciun</title>
          <meta name="description" content="Autentifică-te în contul tău pentru a gestiona rezervările cu Moș Crăciun. Servicii premium de închiriere Moș Crăciun pentru evenimente." />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="Inchiriaza Mos Craciun"
            src="https://inchiriazamoscraciun.ro/uploads/logo_webp/logo-2.svg"
            className="mx-auto h-16 w-auto"
          />
          <h2 className="mt-10 mb-10 text-center text-3xl font-bold tracking-tight text-gray-900">
            Autentificare în cont
          </h2>

          <LoginForm />
        </div>

        <footer className="mt-10 text-center text-gray-500 text-sm">
          <p>&copy; 2024 inchiriazamoscraciun.ro, toate drepturile rezervate.</p>
        </footer>
      </div>
    </HelmetProvider>
  );
};

export default Login;
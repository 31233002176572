import React, { useState, useEffect } from 'react';
import UsersTable from '../components/UsersTable';
import UserDialog from '../components/UserDialog';
import ConfirmDialog from '../../shared/ConfirmDialog';
import { User, getUsers, addUser, updateUser, deleteUser } from '../../services/userService';
import { useNotification } from '../components/Notification';

interface UserData {
  _id?: string; // Adăugăm și _id pentru a putea gestiona editarea
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  role: 'Super Admin' | 'Admin' | 'Moderator';
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [userToEdit, setUserToEdit] = useState<UserData | null>(null);  // Noua stare pentru utilizatorul editat
  const { addNotification } = useNotification();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Eroare la încărcarea utilizatorilor:', error);
      addNotification('error', 'Eroare la încărcarea utilizatorilor', 'Vă rugăm să încercați din nou mai târziu.');
    }
  };

  const handleAddUser = () => {
    setUserToEdit(null);  // Resetăm utilizatorul editat
    setIsDialogOpen(true);
  };

  const handleEditUser = (userId: string) => {
    const user = users.find(u => u._id === userId);
    if (user) {
      const userToEdit: UserData = {
        _id: user._id,  // Preluăm _id pentru a putea face actualizarea corectă
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        role: user.role,
        password: '' // Parola este goală în cazul editării
      };
      setUserToEdit(userToEdit);  // Setăm utilizatorul care urmează să fie editat
      setIsDialogOpen(true);  // Deschidem dialogul
    }
  };

  const handleSaveUser = async (userData: UserData) => {
    try {
      if (userToEdit && userToEdit._id) {
        // Dacă edităm un utilizator existent
        await updateUser(userToEdit._id, userData);
        addNotification('success', 'Utilizator actualizat cu succes', `${userData.firstName} ${userData.lastName} a fost actualizat.`);
      } else {
        // Dacă adăugăm un utilizator nou
        await addUser(userData);
        addNotification('success', 'Utilizator adăugat cu succes', `${userData.firstName} ${userData.lastName} a fost adăugat în sistem.`);
      }
      setIsDialogOpen(false);
      fetchUsers();  // Reîncărcăm lista de utilizatori
    } catch (error) {
      console.error('Eroare la salvarea utilizatorului:', error);
      addNotification('error', 'Eroare la salvarea utilizatorului', 'Vă rugăm să verificați datele și să încercați din nou.');
    }
  };

  const handleDeleteUser = (user: User) => {
    setUserToDelete(user);
    setIsConfirmDialogOpen(true);
  };

  const confirmDeleteUser = async () => {
    if (userToDelete) {
      try {
        await deleteUser(userToDelete._id);
        fetchUsers();
        addNotification('success', 'Utilizator șters cu succes', `${userToDelete.firstName} ${userToDelete.lastName} a fost șters din sistem.`);
      } catch (error) {
        console.error('Eroare la ștergerea utilizatorului:', error);
        addNotification('error', 'Eroare la ștergerea utilizatorului', 'Vă rugăm să încercați din nou mai târziu.');
      }
    }
    setIsConfirmDialogOpen(false);
    setUserToDelete(null);
  };

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Utilizatori</h1>
          <p className="mt-2 text-sm text-gray-700">
            O listă cu toți utilizatorii din sistem
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleAddUser}
          >
            Adaugă utilizator
          </button>
        </div>
      </div>
      <div className="mt-8">
        <UsersTable
          users={users}
          onEditUser={handleEditUser}
          onDeleteUser={handleDeleteUser}
        />
      </div>
      <UserDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={handleSaveUser}
        userToEdit={userToEdit}  // Pasăm utilizatorul pentru editare, dacă există
      />
      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={confirmDeleteUser}
        title="Confirmare ștergere utilizator"
        description={`Sunteți sigur că doriți să ștergeți utilizatorul ${userToDelete?.firstName} ${userToDelete?.lastName}? Această acțiune nu poate fi anulată.`}
        confirmText="Șterge"
        cancelText="Anulează"
      />
    </div>
  );
};

export default Users;